@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "Bubblegum_Sans";
  src: url("./Assets/font/Bubblegum_Sans/BubblegumSans-Regular.ttf");
}

:root {
  --color-cursor: 220, 90, 90;
  --cursor-outline-shade: 0.3;
  --cursor-size: 10px;
  --cursor-outline-size: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-color {
  background-image: linear-gradient(180deg, #0a1a1b 31%, #5c9e90)
}

.header__background-image {
  background-color: #0a1a1b;
  background-image: url("./Assets/img/back.png");
  background-position: 50% 50%;
  background-size: cover;
}

.header__video {
  width: 100%;
}

.mastermind__background-image {
  background-color: #0a1a1b;
  background-image: url("./Assets/img/Teamwork.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.carousel__controls {
  position: absolute;
  top: calc(50% - 20px);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.carousel {
  z-index: 100000000000000;
}

.carousel__control {
  background: rgb(44, 52, 65);
  border: 0px;
  z-index: 100000000000000;
}

.carousel__control::before {
  content: none;
}

.carousel__control--next::after {
  border-width: 0 0 3px 3px;
  left: calc(50% - 24px)
}

.carousel__control--prev::after {
  border-width: 0 0 3px 3px;
  left: calc(50% - 16px)
}


#cursor-dot,
#cursor-dot-outline {
  z-index: 100000000000000;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

#cursor-dot {
  width: var(--cursor-size);
  height: var(--cursor-size);
  background-color: rgba(var(--color-cursor), 1);
}

#cursor-dot-outline {
  width: var(--cursor-outline-size);
  height: var(--cursor-outline-size);
  background-color: rgba(var(--color-cursor), var(--cursor-outline-shade));
}

.shine {
  position: relative;
  overflow: hidden;
  z-index: 100000000000000;
}
.shine::before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  content: "";
  display: block;
  height: 100%;
  left: -75%;
  position: absolute;
  top: 0;
  transform: skewX(-25deg);
  width: 50%;
  z-index: 100000000000000;
}
.shine:hover::before, .shine:focus::before {
  -webkit-animation: shine 0.85s;
          animation: shine 0.85s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.Toastify__toast-container {
  z-index: 10000;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #5c9e90; 
}
